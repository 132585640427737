<template>
  <div class="knives__intro">
    <div class="container">
      <div class="knives__intro-inner">
        <div class="knives__intro-top">
          <h1 class="knives__intro-title">{{ promo.attributes.label }}</h1>
          <div class="knives__intro-discount">
            <img
              v-if="$i18n.locale === 'ru'"
              src="@/assets/img/knives/knives-discount.svg"
              alt=""
            />
            <img
              v-else
              src="@/assets/img/knives/knives-discount-kz.svg"
              alt=""
            />
          </div>
        </div>
        <div class="knives__intro-bottom">
          <div class="knives__timer">
            <div class="knives__timer-item knives__timer-day">
              <span class="knives__timer-number" id="days">20</span>
              <span class="knives__timer-text">{{ $t("knives.days") }}</span>
            </div>
            <div class="knives__timer-item knives__timer-hour">
              <span class="knives__timer-number" id="hours">24</span>
              <span class="knives__timer-text">{{ $t("knives.hours") }}</span>
            </div>
            <div class="knives__timer-item knives__timer-minute">
              <span class="knives__timer-number" id="minutes">60</span>
              <span class="knives__timer-text">{{ $t("knives.minutes") }}</span>
            </div>
            <div class="knives__timer-item knives__timer-second">
              <span class="knives__timer-number" id="seconds">60</span>
              <span class="knives__timer-text">{{ $t("knives.seconds") }}</span>
            </div>
          </div>
          <div class="knives__valid">
            {{ $t("knives.validUntil") }}: {{ promo.attributes.date_end }}
          </div>
        </div>
      </div>
      <img
        class="knives__intro-img"
        src="@/assets/img/knives/knives-intro-img.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "KnivesIntro",
  computed: {
    ...mapState("knivesModule", ["promo"]),
  },
  data() {
    return {
      month: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  },
  mounted() {
    let date = this.promo.attributes.date_end.split("-");
    // Set the date we're counting down to
    var countDownDate = new Date(
      `${this.month[Number(date[1] - 1)]} ${Number(date[2])}, ${
        date[0]
      } 00:00:00`
    ).getTime();
    console.log(countDownDate);
    // Update the countdown every 1 second
    var x = setInterval(function () {
      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the countdown date
      var distance = countDownDate - now;

      // Calculate the days, hours, minutes and seconds left
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Display the result in the HTML elements
      document.getElementById("days").innerHTML = days
        .toString()
        .padStart(2, "0");
      document.getElementById("hours").innerHTML = hours
        .toString()
        .padStart(2, "0");
      document.getElementById("minutes").innerHTML = minutes
        .toString()
        .padStart(2, "0");
      document.getElementById("seconds").innerHTML = seconds
        .toString()
        .padStart(2, "0");

      // If the countdown is over, show a message
      if (distance < 0) {
        clearInterval(x);
        document.getElementById("countdown").innerHTML = "EXPIRED";
      }
    }, 1000);
  },
};
</script>

<style lang="scss" scoped></style>
